import React from "react";
import styles from "./SectionHeader.module.scss";

export type SectionHeaderProps = {
  title: string;
};

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title }) => {
  return <h4 className={styles.header}>{title}</h4>;
};

export default SectionHeader;
